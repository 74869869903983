export const dateToString = (date: Date | string, format: string) => {
  let temp : Date;
  if (typeof date === "string") {
    temp = new Date(date);
  } else {
    temp = date;
  }
  let weekName = ["일", "월", "화", "수", "목", "금", "토"];

  const lessThanTen = (n: any) => {
    return n < 10 ? "0" + n : n;
  }

  return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function ($1: any) {
    switch ($1) {
      case "yyyy": return temp.getFullYear();
      case "yy": return lessThanTen(temp.getFullYear() % 1000);
      case "MM": return lessThanTen(temp.getMonth() + 1);
      case "dd": return lessThanTen(temp.getDate());
      case "E": return weekName[temp.getDay()];
      case "HH": return lessThanTen(temp.getHours());
      case "hh": return lessThanTen(temp.getHours() % 12);
      case "mm": return lessThanTen(temp.getMinutes());
      case "ss": return lessThanTen(temp.getSeconds());
      case "a/p": return temp.getHours() < 12 ? "오전" : "오후";
      default: return $1;
    }
  });
}