import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NormalButton } from '../../components';

import './notFound.scss';

const NotFound = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return(
    <div className="container_not_found">
      <p className="title">{t('not_found_title')}</p>
      <p className="description">{t('not_found_description')}</p>
      <div className="button_box">
        <NormalButton title={t('not_found_button1')} onClick={() => {
          navigate(-1);
        }} />
        <NormalButton title={t('not_found_button2')} onClick={() => {
          navigate('/');
        }} />
      </div>
    </div>
  );
}

export default NotFound;