import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { HeaderProps } from './header.types';
import { Languages, languages } from '../../assets/locales/i18n';
import { useTranslation } from 'react-i18next';

import './header.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, gnbSetting, menuSetting, GNBState, currentTabSetting } from '../../store';

const Header = ({ title, location, tabRef, scrollY, setBtnStatus}: HeaderProps) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location2 = useLocation();
  const gnb:GNBState = useSelector((state: RootState) => state.gnb);

  const { i18n } = useTranslation();
  const [languageOpen, setLanguageOpen] = useState(false);
  const [gnbMouseOver, setGnbMouseOver] = useState(0);
  const [gnbOn, setGnbOn] = useState(0);

  const navMap = title.map((data: any, index : number) => (
    <li
      className={classNames(
        {on : index === gnbOn}, 
        {mouse_over : gnbMouseOver === index + 1},
        {mouse_leave : 
          ((gnb.currentTab === 0 || gnb.currentTab === tabRef.current[index + 1] || gnb.currentTab !== tabRef.current[index + 1]) && gnbMouseOver !== 0 && gnbMouseOver !== index + 1)
        },
        {default : 
          ((gnb.currentTab === 0 && gnb.currentTab !== null) && gnbMouseOver !== index + 1) 
          || ((gnb.currentTab === 0 && gnb.currentTab === null) && gnbMouseOver === index + 1)
          || ((gnb.currentTab === 0 && gnb.currentTab === null) && gnbMouseOver === 0)
        }
      )}
      key={data.id}
    >
      <p
        onClick={() => {
          dispatch(gnbSetting(index + 1));
          dispatch(menuSetting(false));
          dispatch(currentTabSetting(tabRef.current[index + 1]));
          setGnbOn(index);
          setGnbMouseOver(0);
          navigate(`/`);
        }}
        onMouseOver={() => {
          setGnbMouseOver(index + 1);
        }}
        onMouseLeave={() => {
          setGnbMouseOver(0);
        }}
      >
        {data.title}
      </p>
    </li>
  ));

  const handlerLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
    sessionStorage.setItem('language',lang);
  }

  return(
    <header className={classNames({scroll_zero : scrollY === 0}, {menu_open : gnb.menu})}>
      <Link 
        className='logo' 
        to={'/'}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          dispatch(currentTabSetting(0));
          dispatch(gnbSetting(0));
          // dispatch(gnbDefaultSetting(true));
        }}
      >NEXTOR</Link>
      <div 
        className={classNames(`hamburger_button`, {on : gnb.menu})}
        onClick={() => {
          dispatch(menuSetting(!gnb.menu));
        }}
      ></div>
      <div className={classNames(`right_box`, {on : gnb.menu})}>
        <ul className={classNames(`gnb`, {on : gnb.menu})}>
          {navMap}
        </ul>
        {/* language */}
        <div
            className={classNames(`language_box`, {on : languageOpen})}
            onClick={() => {
              setLanguageOpen(!languageOpen);
            }}
          >
            <p>
              {
                i18n.language === 'ko' || i18n.language === 'ko-KR' 
                  ? "KR" 
                  : i18n.language === 'en' || i18n.language === 'en-US' 
                  ? "EN" 
                  : "Language"
              }
            </p>
            <div className={languageOpen ? "language_arrow on" : "language_arrow"}></div>
            <ul className="language_box_inner">
              {/* {LanguageMap} */}
              {languages.map((lang: any) => (
                <li
                  key={lang}
                  className={lang.substr(0, 2) === i18n.language.substr(0, 2) ? 'on' : ""}
                  onClick={() => handlerLanguage(lang)}
                >
                  {
                    lang.substr(0, 2) === 'ko'
                      ? "KR" 
                      : lang.substr(0, 2) === 'en' 
                      ? "EN" 
                      : "Language"
                  }
                </li>
              )
              )}
            </ul>
          </div>
      </div>
    </header>
  );
}

export default Header;