import { useState, useRef, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header, Footer, TopButton, MetaTag } from './components';
import { Nextor, News, NewsDetail, NotFound } from './pages';
import { useDispatch } from 'react-redux';

import { gnbSetting, menuSetting, newsSetting, currentTabSetting } from './store';
import axios from "axios";
import AOS from 'aos';
import "aos/dist/aos.css";

import './assets/css/reset.css';
import './assets/css/app.scss';

import { SERVER_URL } from './utils';
import classNames from 'classnames';

const App = () => {

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Header Menu
  const [title, setTitle] = useState([
    { id: 1, title: t("header1"), link: "/zemiverse" },
    { id: 2, title: t("header2"), link: "/ai" },
    { id: 4, title: t("header4"), link: "/about" },
    { id: 3, title: t("header3"), link: "/news" },
    { id: 5, title: t("header5"), link: "/recruit" },
  ]);

  const tabRef = useRef([]);
  const [bottom, setBottom] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [btnStatus, setBtnStatus] = useState(false); // 버튼 상태

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if(scrollY > 100) {
      setBtnStatus(true);
      if (window.scrollY === (document.documentElement.scrollHeight - document.documentElement.clientHeight)) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    } else {
      setBtnStatus(false);
    }
  }

  const getNewsApi = async () => {
    await axios
      .get(SERVER_URL)
      .then((data) => {
        // console.log(data);
        // console.log(data.data.data);
        dispatch(newsSetting(data.data.data.filter((e:any) => e.lang === i18n.language.substr(0, 2))));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener('scroll', handleFollow);
    return () => {
      window.removeEventListener('scroll', handleFollow);
    }
  });

  useEffect(() => {
    dispatch(currentTabSetting(0));
    dispatch(gnbSetting(0));
    dispatch(menuSetting(false));
  }, []);

  useEffect(() => {
    getNewsApi();
    // Refresh(새로고침)시 최상단으로 이동
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
    setBottom(false);
    setBtnStatus(false);
    setScrollY(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNewsApi();
    if(location.pathname.substr(0,12) === '/news/detail') {
      navigate(`/news/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div 
      // className="App"
      className={classNames(`App`, i18n.language.substr(0,2))}
    >
      <MetaTag/>
      <Header 
        title={title}
        location={location.pathname}
        tabRef={tabRef}
        scrollY={scrollY}
        setBtnStatus={setBtnStatus}
      />
      <Routes>
        <Route path="/" element={<Nextor tabRef={tabRef} />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/detail/:id" element={<NewsDetail />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
      {/* top button */}
      <TopButton bottom={bottom} btnStatus={btnStatus}/>
    </div>
  );
}

export default App;
