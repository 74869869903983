import classNames from "classnames";

import './normalButton.scss';

type NormalButtonProps = {
  title: string,
  out?: boolean,
  onClick?: Function;
}

const NormalButton = ({title, out, onClick}: NormalButtonProps) => {
  return(
    <div 
      className={classNames(`button`, {out : out})}
      onClick={() => {
        if (onClick === undefined) {
          return false;
        } else {
          onClick();
        }
      }}
      data-aos="fade-up"
    >
      <p>{title}</p>
      {out && (
        <div className="icon"></div>
      )}
    </div>
  );
}

export default NormalButton;