const GNB_SETTINGS = 'gnb/GNB_SETTINGS' as const;
const MENU_SETTINGS = 'gnb/MENU_SETTINGS' as const;
const CURRENT_TAB_SETTINGS = 'gnb/CURRENT_TAB_SETTINGS' as const;

type GNBAction =
  | ReturnType<typeof gnbSetting>
  | ReturnType<typeof menuSetting>
  | ReturnType<typeof currentTabSetting>
;

export const gnbSetting = (diff: number) => ({
  type: GNB_SETTINGS,
  payload: diff
});

export const menuSetting = (diff: boolean) => ({
  type: MENU_SETTINGS,
  payload: diff
});

export const currentTabSetting = (diff: any) => ({
  type: CURRENT_TAB_SETTINGS,
  payload: diff
});

export type GNBState = {
  index: number;
  menu: boolean;
  currentTab: any;
};

const initialState: GNBState = {
  index: 0,
  menu: false,
  currentTab: 0
};

function gnb(
  state: GNBState = initialState,
  action: GNBAction
): GNBState {
  switch (action.type) {
    case GNB_SETTINGS:
      return { ...state, index: action.payload };
    case MENU_SETTINGS:
      return { ...state, menu: action.payload };
    case CURRENT_TAB_SETTINGS:
      return {...state, currentTab: action.payload}
    default:
      return state;
  }
}

export default gnb;