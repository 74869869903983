import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import './topButton.scss';

type TopButtonProps = {
  bottom: boolean;
  btnStatus: boolean;
}

const TopButton = ({bottom, btnStatus} : TopButtonProps) => {

  const { i18n } = useTranslation();
  
  return(
    <div 
      className={classNames("top_button", {on : btnStatus}, {bottom : bottom}, i18n.language.substr(0, 2))}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }}
    ></div>
  )
}

export default TopButton;