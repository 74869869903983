import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
// import { persistStore } from "redux-persist";
// import { PersistGate } from "redux-persist/integration/react";
import { legacy_createStore as createStore } from 'redux';

import App from './App';
import rootReducer from './store';

import './assets/locales/i18n';
import { hydrate, render } from "react-dom";

const store = createStore(rootReducer);
// const persistor = persistStore(store);

const element = (
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    {/* </PersistGate> */}
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const root = ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, element);
root.render(element);