import classNames from "classnames";

import { Link } from 'react-router-dom';

import { dateToString } from '../../utils';

interface NewsItemData {
  currentItems: any;
}

const NewsItem = ({currentItems}: NewsItemData) => {

  return (
    <div className="news_items_box">
    {currentItems && currentItems.map((data: any, index : number) => (
      <Link
        key={index}
        className="news_items"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }}
        to={`/news/detail/${data._id}`}
      >
        <div className="left_box">
          <p className={classNames(`icon`, data.category)}>{data.category}</p>
          <p className="news_item_title">{data.title}</p>
          <div className="news_description">
            <p>{(data.content.replace(/<[^>]*>?/g, '').replace(/&nbsp;/g, ' '))}</p>
          </div>
        </div>
        <p className="news_item_date">{dateToString(data.regdate, 'yyyy.MM.dd')}</p>
        <div className="news_items_button"></div>
      </Link>
    ))}
    </div>
  )
}
export default NewsItem;
