type MainBoxProps = {
  title: string;
  description: string;
  image?: boolean;
  email?: string;
  onClick?: Function;
}

const MainBox = ({ title, description, image, email, onClick }: MainBoxProps) => {
  if (email) {
    return (
      <a className="box" href={`mailto:`+email}>
        {image && <div className='image' />}
        <p className="title">{title}</p>
        {description !== '' && <p className="description">{description}</p>}
      </a>
    )
  } else {
    return (
      <div
      className="box"
      onClick={() => {
        if (onClick === undefined) {
          return false;
        } else {
          onClick();
        }
      }}
    >
      {image && <div className='image' />}
      <p className="title">{title}</p>
      {description !== '' && <p className="description">{description}</p>}
    </div>
    )
  }
}

export default MainBox;