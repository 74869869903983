const NEWS_SETTINGS = 'news/NEWS_SETTINGS' as const;
const NEWS_MAIN_TAB_SETTINGS = 'news/NEWS_MAIN_TAB_SETTINGS' as const;

type NEWSAction =
  | ReturnType<typeof newsSetting>
  | ReturnType<typeof newsMainTabSetting>
;

export const newsSetting = (diff: []) => ({
  type: NEWS_SETTINGS,
  payload: diff
});

export const newsMainTabSetting = (diff: number) => ({
  type: NEWS_MAIN_TAB_SETTINGS,
  payload: diff
});

export type NEWSState = {
  data: [];
  mainTab: number;
};

const initialState: NEWSState = {
  data: [],
  mainTab: 0
};

function news(
  state: NEWSState = initialState,
  action: NEWSAction
): NEWSState {
  switch (action.type) {
    case NEWS_SETTINGS:
      return { ...state, data: action.payload };
    case NEWS_MAIN_TAB_SETTINGS:
      return { ...state, mainTab: action.payload };
    default:
      return state;
  }
}

export default news;