type InfoProps = {
  title: string,
  description: string,
  email?: boolean
}

const InfoBox = ({title, description, email} : InfoProps) => {
  return(
    <div className="info" data-aos="fade-up">
      <p className="title">{title}</p>
      {email ? <a href={`mailto:`+description} className="description">{description}</a> : <p className="description">{description}</p>}
    </div>
  );
}

export default InfoBox;