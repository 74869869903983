type TextBoxProps = {
  title: string;
  subtitle?: string;
  description: string;
}

const TextBox = ({ title, description }: TextBoxProps) => {
  return (
      <div className="textbox">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
  );
}

export default TextBox;