import { useTranslation } from 'react-i18next';

import './footer.scss';

const Footer = () => {

  const { t } = useTranslation();

  return(
    <footer>
      <a href="/" className="logo" onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }}>{t('nextor')}</a>
      <div className="right_box">
        <p className="info">
          <span>{t('footer_company')}</span>
          <span>{t('footer_ceo')}</span>
          <span>{t('footer_address')}</span>
          <span>{t('footer_tel')}</span>
          <span>{t('footer_fax')}</span>
        </p>
        <p className="copyright">{t('footer_copyright')}</p>
      </div>
    </footer>
  );
}

export default Footer;